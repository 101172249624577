<script setup> 
    /* IMPORTS */
    import { getOrCreateProcedure } from 'o365-modules';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { ref, onMounted, watch } from 'vue';

    /* VARIABLES */
    let dsREPosition = null;
    let dsREPositionExpertise = null;
    let dsREPerson = null;
    let dsREPersonExpertise = null;
    let dsREPersonTargetExpertise = null;
    let vAreaOpened = [];
    const vExpertise = ref([]);
    const vExpertiseLevels = ref([]); 
    const vActiveDS = ref(null);
    const vAreaBox = ref(null);
    const vSearchString = ref("");
    const vExpertise_ID = ref("");
    const vExpertiseName = ref("");
    const vCompetenceArea = ref("");
    const vShowPromoted = ref(true);
    const props = defineProps({ Position_ID: Number,
                                Person_ID: Number,
                                RefreshDataObject: Object,
                                RefreshDataObjectRow: Object,
                                OrgUnitIdPath: String,
                                IsTargetExpertise: {type: Boolean, default: false}
    });
    const emit = defineEmits(['expertiseAdded', 'expertiseLevelsAdded']);

    /* PROPS LISTENERS */
    const vPerson_ID = ref(props.Person_ID);
    const vPosition_ID = ref(props.Position_ID);

    /* PROCEDURES */
    const procDelPositionsExpertise = getOrCreateProcedure({ id: "procDelPositionsExpertise", procedureName: "astp_Resource_DelPositionsExpertise" });
    const procInsPositionsExpertise = getOrCreateProcedure({ id: "procInsPositionsExpertise", procedureName: "astp_Resource_InsPositionsExpertise" });
    const procDelPersonsExpertise = getOrCreateProcedure({ id: "procDelPersonsExpertise", procedureName: "astp_Resource_DelPersonsExpertise" });
    const procInsPersonsExpertise = getOrCreateProcedure({ id: "procInsPersonsExpertise", procedureName: "astp_Resource_InsPersonsExpertise" });

    /* GENERIC DATASOURCES */
    const dsRECertLevels = getOrCreateDataObject({
        id: 'dsRECertLevels' + "_" + crypto.randomUUID(),
        viewName: "aviw_Resource_CertificationLevels",
        maxRecords: 25,
        loadRecents: false,
        selectFirstRowOnLoad: false,
        fields: [{ name: "ID" }, { name: "CompetenceAreaType_ID" }, { name: "Name" }, { name: "Level",  sortOrder: 1 }, { name: "NullCol" }],
    });

    const dsRECompAreas = getOrCreateDataObject({
        id: 'dsRECompAreas' + "_" + crypto.randomUUID(),
        viewName: "aviw_Resource_CompetenceAreas",
        maxRecords: 250,
        loadRecents: false,
        selectFirstRowOnLoad: true,
        fields: [{ name: "ID" }, { name: "Name", sortOrder: 1 }, { name: "CompetenceAreaType_ID" }, { name: "CompetenceAreaType" }, { name: "Type" }, { name: "Expertise" }, { name: "SearchColumn"}],
    });

    const dsRECompAreaTypes = getOrCreateDataObject({
        id: 'dsRECompAreaTypes' + "_" + crypto.randomUUID(),
        viewName: "aviw_Resource_CompetenceAreaTypes2",
        maxRecords: 100,
        loadRecents: false,
        distinctRows: true,
        selectFirstRowOnLoad: true,
        whereClause: props.OrgUnitIdPath && vShowPromoted.value ? `'${props.OrgUnitIdPath}' LIKE IdPath + '%'` : "",
        fields: [{ name: "PrimKey" }, { name: "ID" }, { name: "Name", sortOrder: 1 }, { name: "Type" }],
    }); 

    const dsREExpertise = getOrCreateDataObject({
        id: 'dsREExpertise' + "_" + crypto.randomUUID(),
        viewName: "aviw_Resource_Expertise",
        maxRecords: -1,
        loadRecents: false,
        selectFirstRowOnLoad: true,
        fields: [{ name: "ID" }, { name: "Name", sortOrder: 1 }, { name: "CompetenceArea_ID" }, { name: "CompetenceArea" }, { name: "CompetenceAreaType_ID" }, { name: "CompetenceAreaType" }, { name: "SearchColumn" }, { name: "HasExpertiseLevels" }, { name: "HasCertificationLevels" }, { name: "NullCol" }],
    });

    const dsREExpertiseLevels = getOrCreateDataObject({
        id: 'dsREExpertiseLevels' + "_" + crypto.randomUUID(),
        viewName: "aviw_Resource_ExpertiseLevels",
        maxRecords: 25,
        loadRecents: false,
        selectFirstRowOnLoad: false,
        fields: [{ name: "ID" }, { name: "CompetenceAreaType_ID" }, { name: "Name" }, { name: "Level", sortOrder: 1 }, { name: "Title" }, { name: "NullCol" }],
    });

    /* WATCH */
    watch(() => props.Person_ID, (first, second) => {
        vPerson_ID.value = first;
        loadDataObjects();
    });

    watch(() => props.Position_ID, (first, second) => {
        vPosition_ID.value = first;
        loadDataObjects();
    });

    watch(vShowPromoted, newVal => {
        vShowPromoted.value = Boolean(newVal);
        dsRECompAreaTypes.recordSource.whereClause = vShowPromoted.value && props.OrgUnitIdPath ? `'${props.OrgUnitIdPath}' LIKE IdPath + '%'` : "";
        dsRECompAreaTypes.load();
    });

    watch(() => props.OrgUnitIdPath, async (newVal) => {
        if (newVal) {
            dsRECompAreaTypes.recordSource.whereClause = vShowPromoted.value && newVal ? `'${newVal}' LIKE IdPath + '%'` : "";
            await dsRECompAreaTypes.load();
        }
    });

    /* ONMOUNTED */
    onMounted(() => {
        loadDataObjects();
    });

    /* FUNCTIONS */
    async function loadDataObjects() {
        if(vPosition_ID.value && vPerson_ID.value) {
            throw new Error($t('Can\'t bind with both Person_ID and Position_ID in props'));
        } else if(props.IsTargetExpertise && !vPerson_ID.value) {
            throw new Error($t('Person_ID has to be provided when using IsTargetExpertise'));
        }

        dsRECertLevels.load(); 
        dsRECompAreas.load();  
        dsRECompAreaTypes.load(); 
        dsREExpertise.load();  
        dsREExpertiseLevels.load();

        vAreaOpened = [];
        dsREPosition = null;
        dsREPositionExpertise = null;
        dsREPerson = null;
        dsREPersonExpertise = null;
        dsREPersonTargetExpertise = null;

        // Dataobjects for binding
        dsREPosition = vPosition_ID.value ? getOrCreateDataObject({
            id: `dsREPosition_${vPosition_ID.value}`,
            viewName: "aviw_Resource_Positions",
            maxRecords: 1,
            loadRecents: false,
            selectFirstRowOnLoad: true,
            whereClause: vPosition_ID.value ? `ID=${vPosition_ID.value}` : "1=2",
            fields: [{ name: "ID" }],
        }) : null;

        dsREPositionExpertise = vPosition_ID.value ? getOrCreateDataObject({
            id: `dsREPositionExpertise_${vPosition_ID.value}`,
            viewName: "aviw_Resource_PositionsExpertise",
            uniqueTable: "atbv_Resource_PositionsExpertise",
            allowInsert: true,
            allowUpdate: true,
            allowDelete: true,
            masterDataObject_ID: `dsREPosition_${vPosition_ID.value}`,
            masterDetailDefinition: [{"masterField": "ID", "detailField": "Position_ID", "operator": "equals"}],
            maxRecords: 250,
            loadRecents: false,
            selectFirstRowOnLoad: true,
            fields: [{ name: "ID" }, { name: "Position_ID" }, { name: "Expertise_ID" }, { name: "ExpertiseLevel_ID" }, { name: "CertificationLevel_ID" }, { name: "ExpertiseLevel" }, { name: "ExpertiseLevelName" }, { name: "CertificationLevelName" }, { name: "Expertise" }, { name: "CompetenceArea_ID" }],
        }) : null;
        
        dsREPerson = vPerson_ID.value ? getOrCreateDataObject({
            id: `dsREPerson_${vPerson_ID.value}`,
            viewName: "aviw_Resource_Persons",
            maxRecords: 1,
            loadRecents: false,
            selectFirstRowOnLoad: true,
            whereClause: vPerson_ID.value ? `ID=${vPerson_ID.value}` : "1=2",
            fields: [{ name: "ID" }],
        }) : null;

        dsREPersonExpertise = vPerson_ID.value && !props.IsTargetExpertise ? getOrCreateDataObject({
            id: `dsREPersonExpertise_${vPerson_ID.value}`,
            viewName: "aviw_Resource_PersonsExpertise4",
            uniqueTable: "atbv_Resource_PersonsExpertise",
            masterDataObject_ID: `dsREPerson_${vPerson_ID.value}`,
            masterDetailDefinition: [{"masterField": "ID", "detailField": "Person_ID", "operator": "equals"}],
            maxRecords: 250,
            loadRecents: false,
            selectFirstRowOnLoad: true,
            whereClause: vPerson_ID.value ? `IsValid=1` : "1=2",
            fields: [{ name: "ID" }, { name: "Person_ID" }, { name: "Expertise_ID" }, { name: "ExpertiseLevel_ID" }, { name: "CertificationLevel_ID" }, { name: "Expertise" }, { name: "IsValid" }, { name: "ExpertiseLevel" }, { name: "ExpertiseLevelName" }, { name: "CertificationLevelName" }, { name: "CompetenceArea_ID" }],
        }) : null;

        dsREPersonTargetExpertise = vPerson_ID.value && props.IsTargetExpertise ? getOrCreateDataObject({
            id: `dsREPersonTargetExpertise_${vPerson_ID.value}`,
            viewName: "aviw_Resource_PersonsTargetExpertise2",
            uniqueTable: "atbv_Resource_PersonsTargetExpertise",
            masterDataObject_ID: `dsREPerson_${vPerson_ID.value}`,
            masterDetailDefinition: [{"masterField": "ID", "detailField": "Person_ID", "operator": "equals"}],
            maxRecords: 250,
            loadRecents: false,
            selectFirstRowOnLoad: true,
            whereClause: props.IsTargetExpertise && vPerson_ID.value ? `IsValid=1` : "1=2",
            fields: [{ name: "ID" }, { name: "Person_ID" }, { name: "Expertise_ID" }, { name: "ExpertiseLevel_ID" }, { name: "CertificationLevel_ID" }, { name: "Expertise" }, { name: "IsValid" }, { name: "ExpertiseLevel" }, { name: "ExpertiseLevelName" }, { name: "CertificationLevelName" }, { name: "CompetenceArea_ID" }],
        }) : null;

        if(props.IsTargetExpertise && vPerson_ID.value) {
            vActiveDS.value = dsREPersonTargetExpertise;
        } else if(vPosition_ID.value) {
            vActiveDS.value = dsREPositionExpertise;
        } else if (vPerson_ID.value) {
            vActiveDS.value = dsREPersonExpertise;
        }

        vPosition_ID.value ? dsREPosition.load() : null;
        vPerson_ID.value ? dsREPerson.load() : null;
        vActiveDS.value ? vActiveDS.value.load() : null;
    }
    
    async function expLevelCallback(pElement) {
        if(vPosition_ID.value) {
            await procInsPositionsExpertise.execute({ Position_ID: vPosition_ID.value, Expertise_ID: vExpertise_ID.value, ExpertiseLevel_ID: pElement.item ? pElement.item.ID : null, SetExpLevel: 1 });
        } else if(vPerson_ID.value) {
            await procInsPersonsExpertise.execute({ Person_ID: vPerson_ID.value, Expertise_ID: vExpertise_ID.value, ExpertiseLevel_ID: pElement.item ? pElement.item.ID : null, SetExpLevel: 1, IsTargetExpertise: props.IsTargetExpertise });
        } else {
            // Handle no datasource - store locally
            const vIndex = vExpertiseLevels.value.findIndex(item => item.Expertise_ID === vExpertise_ID.value);
            const vIndexCertLevel = vExpertiseLevels.value.findIndex(item => item.Expertise_ID === vExpertise_ID.value && item.CertificationLevel_ID !== null);
            if(pElement.item) {
                if(vIndex > -1) {
                    vExpertiseLevels.value[vIndex]["ExpertiseLevel_ID"] = pElement.item.ID;
                    vExpertiseLevels.value[vIndex]["ExpertiseLevel"] = pElement.Name;
                    vExpertiseLevels.value[vIndex]["ExpertiseLevelVal"] = pElement.Level;
                } else {
                    vExpertiseLevels.value.push({ Expertise_ID: vExpertise_ID.value, ExpertiseLevel_ID: pElement.item.ID, Expertise: vExpertiseName.value, ExpertiseLevel: pElement.Name, ExpertiseLevelVal: pElement.Level, CertificationLevel_ID: null, CertificationLevel: null, CertificationLevelVal: null, CompetenceArea: vCompetenceArea.value });
                }
            } else { 
                if(vIndexCertLevel > -1) {
                    vExpertiseLevels.value[vIndex]["ExpertiseLevel_ID"] = null;
                    vExpertiseLevels.value[vIndex]["ExpertiseLevel"] = null;
                    vExpertiseLevels.value[vIndex]["ExpertiseLevelVal"] = null;
                } else {
                    vExpertiseLevels.value.splice(vIndex, 1);
                }
            }
            emit('expertiseLevelsAdded', vExpertiseLevels.value);
        }   
        vActiveDS.value ? vActiveDS.value.load() : null;
    }

    async function cerLevelCallback(pElement, p2, p3) {
        if(vPosition_ID.value) {
            await procInsPositionsExpertise.execute({ Position_ID: vPosition_ID.value, Expertise_ID: vExpertise_ID.value, CertificationLevel_ID: pElement.item ? pElement.item.ID : null, SetCerLevel: 1 });
        } else if(vPerson_ID.value) {
            await procInsPersonsExpertise.execute({ Person_ID: vPerson_ID.value, Expertise_ID: vExpertise_ID.value, CertificationLevel_ID: pElement.item ? pElement.item.ID : null, SetCerLevel: 1, IsTargetExpertise: props.IsTargetExpertise });
        } else {    
            // Handle no datasource - store locally
            const vIndex = vExpertiseLevels.value.findIndex(item => item.Expertise_ID === vExpertise_ID.value);
            const vIndexExpLevel = vExpertiseLevels.value.findIndex(item => item.Expertise_ID === vExpertise_ID.value && item.ExpertiseLevel_ID !== null);
            if(pElement.item) {
                if(vIndex > -1) {
                    vExpertiseLevels.value[vIndex]["CertificationLevel_ID"] = pElement.item.ID;
                    vExpertiseLevels.value[vIndex]["CertificationLevel"] = pElement.Name;
                    vExpertiseLevels.value[vIndex]["CertificationLevelVal"] = pElement.Level;
                } else {
                    vExpertiseLevels.value.push({ Expertise_ID: vExpertise_ID.value, ExpertiseLevel_ID: null, Expertise: vExpertiseName.value, ExpertiseLevel: null, ExpertiseLevelVal: null, CertificationLevel_ID: pElement.item.ID, CertificationLevel: pElement.Name, CertificationLevelVal: pElement.Level, CompetenceArea: vCompetenceArea.value });
                }
            } else { 
                if(vIndexExpLevel > -1) {
                    vExpertiseLevels.value[vIndex]["CertificationLevel_ID"] = null;
                    vExpertiseLevels.value[vIndex]["CertificationLevel"] = null;
                    vExpertiseLevels.value[vIndex]["CertificationLevelVal"] = null;
                } else {
                    vExpertiseLevels.value.splice(vIndex, 1);
                }
            }
            emit('expertiseLevelsAdded', vExpertiseLevels.value);
        }
        vActiveDS.value ? vActiveDS.value.load() : null;
    }

    async function expAddedOrRemoved(pElement, pExpertise_ID, pName) {
        if(vPosition_ID.value) {
            if(pElement.target.checked) {
                await procInsPositionsExpertise.execute({ Position_ID: vPosition_ID.value, Expertise_ID: pExpertise_ID });
            } else { 
                await procDelPositionsExpertise.execute({ Position_ID: vPosition_ID.value, Expertise_ID: pExpertise_ID });
            }
        } else if(vPerson_ID.value) {
            if(pElement.target.checked) {
                await procInsPersonsExpertise.execute({ Person_ID: vPerson_ID.value, Expertise_ID: pExpertise_ID, IsTargetExpertise: props.IsTargetExpertise });
            } else { 
                await procDelPersonsExpertise.execute({ Person_ID: vPerson_ID.value, Expertise_ID: pExpertise_ID, IsTargetExpertise: props.IsTargetExpertise });
            }
        } else {
            // Handle no datasource - store locally
            if(pElement.target.checked) {
                vExpertise.value.push({ Expertise_ID: pExpertise_ID, Expertise: pName, CompetenceArea: vCompetenceArea.value });
            } else { 
                const vIndex = vExpertise.value.findIndex(item => item.Expertise_ID === pExpertise_ID);
                vIndex !== -1 ? vExpertise.value.splice(vIndex, 1) : null;
            }
            emit('expertiseAdded', vExpertise.value);
        }
        vActiveDS.value ? vActiveDS.value.load() : null;
    }

    function setExpertise(pExpertise_ID, pName, pCompetenceArea) {
        vExpertise_ID.value = pExpertise_ID;
        vExpertiseName.value = pName;
        vCompetenceArea.value = pCompetenceArea;
    }

    function returnValueExpChk(pExpertise_ID) {
        return vActiveDS.value?.data.filter(x => x.Expertise_ID === pExpertise_ID).length > 0 ? true : false;
    }

    function showArea(pArea_ID) {
        return vActiveDS.value?.data.filter(x => x.CompetenceArea_ID === pArea_ID).length > 0 || vAreaOpened.indexOf(pArea_ID) > -1 ? true : false;
    }

    function toggleArea(pArea_ID) {
        const vIndex = vAreaOpened.indexOf(pArea_ID);
        vIndex > -1 ? vAreaOpened.splice(vAreaOpened.indexOf(pArea_ID), 1) : vAreaOpened.push(pArea_ID);
        dsRECompAreas.load();
    }

    function togglePromoted(pElement) {
        vShowPromoted.value = pElement.target.checked ? true : false;
    }

    function refreshDataObject() {
        if(props.RefreshDataObject) {
            props.RefreshDataObject.load();
        }
        if(props.RefreshDataObjectRow) {
            props.RefreshDataObjectRow.refreshRow();
        }
    };
</script>

<template>
    <OModal ref="areasOfExpertiseModal" :title="$t('Areas of Expertise')" dialogClass="modal-xl" @hidden="refreshDataObject">
        <div class="modal-body">
            <div class="app-container">
                <div class="d-flex flex-row" style="align-items: baseline;">
                    <div class="form-group mt-2" style="flex: auto;">
                        <input type="search" class="form-control area-expertise-search" :placeholder="$t('Search')" v-model="vSearchString">
                    </div>
                    <div class="form-check form-switch ms-2" id="toggle-promoted-expertise-container">
                        <input type="checkbox" class="form-check-input" id="toggle-promoted-expertise" checked @change="togglePromoted">
                        <label class="form-check-label" for="toggle-promoted-expertise"> {{ $t('Only show promoted Expertise') }}</label>
                    </div>
                </div>  

                <div class="row" v-for="(rowAreaTypes, indexAreaTypes) in dsRECompAreaTypes.data" :key="indexAreaTypes">
                    <div class="col-12">
                        <h5 class="text-info py-2">{{ rowAreaTypes.Name }}</h5>
                    </div>

                    <div v-for="(rowArea, indexArea) in dsRECompAreas.data.filter(x => x.CompetenceAreaType_ID === rowAreaTypes.ID && x.SearchColumn.toLowerCase().indexOf(vSearchString.toLowerCase()) > -1)" :key="indexArea" class="mb-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="card-header border p-2" style="cursor:pointer">
                            <h6 class="mb-0 font-weight-normal" @click="toggleArea(rowArea.ID)">
                                <span>{{ rowArea.Name }}</span>
                                <i class="bi text-muted areaBoxChevron" style="cursor: pointer;float:right;" :class="showArea(rowArea.ID) ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
                            </h6> 
                        </div>
            
                        <div class="card-body border border-top-0 pl-2 p-1 areaBoxContainer" :class="showArea(rowArea.ID) ? '' : 'd-none'" ref="vAreaBox">
                            <div v-for="(rowExp, indexExp) in dsREExpertise.data.filter(x => x.CompetenceArea_ID === rowArea.ID && x.SearchColumn.toLowerCase().indexOf(vSearchString.toLowerCase()) > -1)" :key="indexExp" style="min-width: 310px;" :id="'compAreaDiv-'+rowExp.CompetenceArea_ID">
                                <div class="pl-1" @click="setExpertise(rowExp.ID, rowExp.Name, rowExp.CompetenceArea)">  
                                    <label v-if="!(rowExp.HasExpertiseLevels || rowExp.HasCertificationLevels)" class="mb-0 labelForInput" style="cursor:pointer">
                                        <input type="checkbox" 
                                            style="width:18px;height:18px;" 
                                            class="align-middle expertiseBothInputs expertiseCheckbox"
                                            :checked="returnValueExpChk(rowExp.ID)" 
                                            @change="expAddedOrRemoved($event, rowExp.ID, rowExp.Name)">
                                        <span>{{ rowExp.Name }}</span> 
                                    </label>
        
                                    <div v-if="rowExp.HasExpertiseLevels || rowExp.HasCertificationLevels">
                                        <label> 
                                            <ODataLookup v-if="rowExp.HasExpertiseLevels" 
                                                class="text-center levelInput"
                                                :title="$t('Expertise Level')"
                                                :data="dsREExpertiseLevels.data.filter(x => x.CompetenceAreaType_ID === rowAreaTypes.ID)"
                                                :value="dsREPositionExpertise !== null ? dsREPositionExpertise.data.filter(x => x.Expertise_ID === rowExp.ID)[0]?.ExpertiseLevelName : dsREPersonExpertise !== null ? dsREPersonExpertise.data.filter(x => x.Expertise_ID === rowExp.ID)[0]?.ExpertiseLevelName : dsREPersonTargetExpertise !== null ? dsREPersonTargetExpertise.data.filter(x => x.Expertise_ID === rowExp.ID)[0]?.ExpertiseLevelName : vExpertiseLevels !== null ? vExpertiseLevels.filter(x => x.Expertise_ID === rowExp.ID && x.ExpertiseLevel_ID !== null)[0]?.ExpertiseLevel : null"
                                                :bind="expLevelCallback">
                                                <OColumn field="Name" width="200" :header-title="$t('Name')" :header-name="$t('Name')" />
                                                <OColumn field="Title" width="200" :header-title="$t('Title')" :header-name="$t('Title')" />
                                                <OColumn field="Level" width="100" :header-title="$t('Level')" :header-name="$t('Level')" />
                                            </ODataLookup>
                                            
                                            <ODataLookup v-if="rowExp.HasCertificationLevels" 
                                                class="text-center levelInput"
                                                :title="$t('Certification Level')"
                                                :data="dsRECertLevels.data.filter(x => x.CompetenceAreaType_ID === rowAreaTypes.ID)" 
                                                :value="dsREPositionExpertise !== null ? dsREPositionExpertise.data.filter(x => x.Expertise_ID === rowExp.ID)[0]?.CertificationLevelName : dsREPersonExpertise !== null ? dsREPersonExpertise.data.filter(x => x.Expertise_ID === rowExp.ID)[0]?.CertificationLevelName : dsREPersonTargetExpertise !== null ? dsREPersonTargetExpertise.data.filter(x => x.Expertise_ID === rowExp.ID)[0]?.CertificationLevelName : vExpertiseLevels !== null ? vExpertiseLevels.filter(x => x.Expertise_ID === rowExp.ID && x.CertificationLevel_ID !== null)[0]?.CertificationLevel : null"
                                                :bind="cerLevelCallback">
                                                <OColumn field="Name" width="200" :header-title="$t('Name')" :header-name="$t('Name')" /> 
                                                <OColumn field="Level" width="100" :header-title="$t('Level')" :header-name="$t('Level')" />
                                            </ODataLookup>
                                            <span>{{ rowExp.Name }}</span> 
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </OModal>
</template>

<style>
    .levelInput {   
        width: 35px;
        height: 18px; 
        cursor: pointer;
        background-image: none;
        border-width: thin;
        text-align: center;
        margin: 0px 2px 0px 2px;
    }

    .lookup-icon {
        padding-right: 0rem !important;
    }
</style>